<template>
  <div>
     <!-- <iframe :src="url" frameborder="0" style="width: 100%; height: 100vh; overflow: none;"></iframe> -->
    <pdf
      ref="pdf" 
      v-for="i in numPages"
    :key="i"
    :src="url"
    :page="i"
      ></pdf>
  </div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  components:{
      pdf
  },
  data(){
      return {
          url:"./static/Instruction.pdf",
          numPages: null, // pdf 总页数
      }
  },
  mounted() {
      this.getNumPages()
  },
  methods: {
    getNumPages() {
      let loadingTask = pdf.createLoadingTask(this.url);
      loadingTask.promise.then(pdf => {
         this.numPages = pdf.numPages
      }).catch(err => {
        console.error('pdf loading failed', err);
      })
    },
  }
}
</script>