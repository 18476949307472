<template>
    <div class="content">
      <h2>Privacy Policy</h2>
      <h2>Effective date: Jun 21, 2021.</h2>
      <p>
        <pre>
Your California Privacy Disclosures >
This Privacy Policy applies to certain information, as defined below, that Charmland Technologies Ltd, and its affiliated companies within the Forfend Group (hereinafter refer to as “Forfend”, “Forfend Security”, “we”, “our” or “us”) collects, uses, discloses, processes, and protects any personal information that you give us or that we collect from you, when you use our products and services located at websites (https://www.forfendsecurity.com), and applications(App) (the “Services”). Please take a moment to read and understand our Privacy Policy and let us know if you have any questions.
Personal data means any electronic or other information which alone or jointly with other information can be used to identify a natural person or make him/her identifiable. This Policy explains how we process your personal data, but does not cover all processing scenarios as you will be informed in specific processing activity. Therefore, before using a specific product or service, it is recommended that you read the Privacy Policy or supplementary Policy released by us for that product or service to understand how it processes your personal data, how you can exercise your data subject rights, and how to contact us.
This Policy describes:
1. How We Collect & Use Your Personal Data
2. Cookies & Similar Technologies
3. How We Disclose Personal Data
4. How to Access & Control Your Personal Data
5. How We Protect Your Personal Data
6. How We Process Children's Personal Data
7. Third-Party Providers and Their Services
8. How your personal information is transferred globally
9. How we Updates This Privacy Policy
10. Contact Us
1 How We Collect & Use Your Personal Data
1.1 What information is collected by us
In order to provide our services to you, we will ask you to provide personal information that is necessary to provide those services to you. We will only collect the information that is necessary for its specified, concrete, explicit and legitimate purposes and not further processed in a manner that is incompatible with those purposes. You have the right to choose whether or not to provide the information we have requested, but in most cases, if you do not provide your personal information, we may not be able to provide you with our products or services or respond to your queries.
Depending on the service you choose, we may collect the following types of information:
1.1.1 Information you provide to us
• Your Account information: You provide your email address, or log in for a third-party account (like Facebook、Google), to create an online or application account (“Account”) or subscribe to our communications. If you refuse to provide such information, you may not be able to use the Services properly.
• Order related information: You provide your name, telephone number, email address, Delivery address, Billing address, Postal Code. We use a third-party payment processor (currently Stripe) to assist in securely processing your personally identifiable payment information. When you place an order through the Services, the credit card information（bank account number, account holder name, credit card number, expiration date, secure code, and other information） that you provide is encrypted and transmitted directly to Stripe. We do not store your credit card information and do not control and are not responsible for Paypal or its collection or use of your information. You may find out more about how Paypal stores and uses your credit card information by accessing Paypal’s Privacy Policy.
• Cloud Storage Services information: When you subscribe to the cloud storage services, we may provide you with cloud storage where you can sync and back up your data.
• Protection Plan Services information: When you subscribe to the protection plan services, you provide your personal data (Your name, phone number, devices, address, emergency contact) to implement the first responder dispatch service.
• Customer Support: When you contact us, you can provide us with information about your use of the Services, including information about the Forfend electronics and/or consumer products (“Products”) that you own contact details (telephone number, email address, shipping address), order/invoicing details) and voice recording(if you contact us by phone), so that we can provide customer support.
• Your voice: When you use one of our voice assisted technology supported Products, like, with your permission, your voice will be acquired by that Product and then transferred by that Product back to your elected third-party account (“Third-Party Account”) service as a command, such an Amazon Alexa or Google Assistant command, and relay you the requested information.
• Mobile location data: When you log in to the Forfend Security app for the first time, you’ll be asked for permission to share location data from your mobile device. If you grant this permission, the Forfend Security app will know which region you are located and the Forfend Security app will connect to the optimal server which corresponds to your region automatically.
• When you add devices in the Forfend Security app, you’ll be asked for permission to share location data from your mobile device. For an Android app, it requires the location permission to get the Wi-Fi list from the mobile phone for adding devices. For an iOS app, it requires the location permission to get the Local Network permission which can make the Forfend devices work in WLAN/LAN.
• You may choose to enable the Geofencing feature. If you do this, you’ll be asked for permission to share location data from your mobile device. If you grant this permission, the Forfend Security app uses standard location services built into iOS and Android. This data will help the Forfend System to know when you leave/arrive home and arm/disarm the devices automatically.
1.1.2 Information that we collect in your use of services
• Product monitoring: We collect information about how you set up and use our Products, such as: functions used; operations performed; time, frequency, and duration of your activities within the Services, and any unique identifiers associated with your Product. For example, we collect the time period you connected the Product to the Application and the time of the last connection, as well as what operations you used in the Account, settings, and other functions in our Products.
• Images and biometric information: If you choose to enable imaging and biometric features in our Products, we will collect and process certain information collected from our Products, such as face images and underlying face prints. This information enables your Product to recognize familiar faces and to notify you of familiar and unknown people. These features may also allow you to utilize the Services to create and save video or photographic images of individuals. Depending on where you live and how you configure the Products and Services, you may need to get explicit consent before using our Products to create and save video or photographic images and/or scan and match the faces of people. Except when you choose to store this information in the Cloud Storage Service, this information will be kept in the local Products only.
• Device properties and operations: We collect certain information from and about your smartphone, tablet, or personal computer (“Device”), such as: Device number, model, hardware and software version, signal intensity, Internet Protocol (IP) addresses, and other information. We also collect information about the operations you perform on the Device, such as time, frequency, and duration of use.
• Device signals: We collect information that your Device sends out or receives to tailor the Services to our users in different regions, such as: geo-location, IP addresses, and external hardware information from your Device about surrounding Wi-Fi access points, beacons, and cell towers. You can enable and disable communicating such information to us by adjusting your Device security and privacy settings.
• Server logs: A server log is a list of the activities that a server performs. Forfend’s servers automatically collect and store in server logs certain information, such as: your search queries, IP addresses, hardware settings, browser type, browser the date and time of your request, referral URL, and certain cookies that identify your browser or Account.
1.1.3 Information from third-party sources
When permitted by law, we will collect information about you from third-party sources. For example,
• We collect and use information obtained from Facebook, Google, Amazon, and other accounts you use to login to the Services (“Third-Party Accounts”), such as: your name, your user name or handle, birth date, picture, and other details you may have on your Third-Party Account profile. We do not have access to your Third-Party Account passwords.
• for certain services that might include account and financial transactions, with your authorization, we may validate the information provided by you (such as phone number) through legitimate third-party sources for security and fraud prevention purposes;
• we may receive designated identifier symbols (such as IMEI from an advertiser) to provide advertising services to you;
• information about you that others provided to us (e.g. your delivery address that other users may provide to us when they buy products for you via forfendsecurity.com or App).
1.1.4 Non-personally identifiable information
Non-identifiable data refers to data that cannot be used to identify an individual. Examples of non-identifiable data include statistical data generated when you use a specific service (e.g. daily usage events, page access events, page access time events and session events). We will collect statistics information to understand how users use our products and services. By doing so, we can improve our services to better meet your requirements.
1.2 How we use the personal information that we collect
The purpose of collecting personal information is to provide you with products and/or services, and to ensure that we comply with applicable laws, regulations and other regulatory requirements. This involves:
• Creating and maintaining your Forfend Account: Personal information collected when you create a Forfend Account on our websites or through mobile application, is used to create the personal Forfend Account and profile page for you.
• Processing your purchase order: Information related to e-commerce orders is used for processing the purchase order and related after-sales services, including customer support and re-delivery.
• To send periodic emails: The email address you provide for order processing or for your Account may be used to send you information and updates pertaining to your order, in addition to receiving occasional company news, updates, related Product or Services information, including sending you updates to our terms and policies and other important messages from time to time. We may also send you marketing and promotional communications we believe might be relevant to your interests. If you no longer wish to receive certain types of promotional materials, you may opt-out by the method provided in the message (such as the unsubscribe link at the bottom of the message) unless otherwise specified under applicable laws.
• Our service: We operate and provide services, including providing customer support and improving, repairing, and customizing the Services. We use your information to understand how people use and to evaluate and improve the Services. Furthermore, we use your information to research, develop, and test new services, Products, and features, as well as perform troubleshooting activities. We also use your information to respond to you when you contact us. We use cookies to operate, provide, improve, understand, and customize the Services.
• Security and privacy: We use your information to verify Account details and activity. We also use your information to promote the security and privacy of the Services, such as investigating suspicious activities or violations of our terms.
• Advertising and business information: We may show advertisements to you and others for our Products and third-party products based on the information we have collected from you, such as: what you have installed, accessed, used, and purchased.
• Accessing Third-Party Accounts: We use your information, including Third-Party Account information, to permit your Products and the Services to access Third-Party Accounts. These Third-Party Accounts may allow you to share personal information through our Services, such as Facebook posts or pictures. Because this kind of information can be public, you should exercise care in what you choose to submit to Third-Party Accounts. Please adjust your Third-Party Account privacy settings appropriately.
• Process pursuant to laws and regulations, e.g. tax, authority requests.
2 Cookies & Similar Technologies
2.1 Cookie
A cookie is a text file stored by a web server on a computer or mobile device, and the content of a cookie can be retrieved and read only by the server that created the cookie. Cookies are unique to the browser or mobile application you are using. The text in a cookie often consists of identifiers, site names, and some numbers and characters.
Sometimes, we stores cookies on computers or mobile devices for the purpose of improving user experience, including the following scenarios:
• Technical cookies: Login and verification. When you use a Forfend ID to log in to a website, the "session-based" cookies ensure that your visit to this site functions as smoothly as possible.
• Personalization cookies: Storage of your preferences and settings. A website can use cookies to save settings, such as the language setting and font size on your computer or mobile device, items in your shopping cart, and other browser preferences.
• Advertising cookies: We use cookies to collect information about your online activities and interests and provide you with advertisements that correlate most highly with you.
• Statistical cookies: With cookies, we can collect information about your use of our websites and other applications, either for a single visit (using a session cookie) or for repeated visits (using a persistent cookie).
• Social Media Cookies.：Social media cookies are linked to services provided by third parties, such as 'Like' buttons and 'Share' buttons. The third party provides these services in return for recognizing that you have visited our websites.
You can manage or delete cookies at your own preference. For details, visit www.allaboutcookies.org. You can clear all the cookies stored on your computer, and most current web browsers provide the option of blocking cookies. However, blocking cookies will require you to change your user settings every time you visit our website. Find out how to manage cookie settings for your browser here(e.g. Google Chrome): Internet Explorer > Google Chrome > Mozilla Firefox > Safari > Opera.
If you clear cookies, you will need to change your settings the next time you visit our websites. Note that some of our services require the use of cookies. Disabling cookies may affect your use of some or all functions of these services.
Our Services use the following cookies:
• Strictly necessary cookies： which are required for the operation of the Services. Without them, for example, you would not be able to use your Account.
• Analytical/performance cookies：which allow Forfend to recognize and count the number of visitors, learn how visitors navigate the Services, and improve the Services.
• Functionality cookies： which Forfend uses to recognize you when you return to the Services.
2.2. Web Beacons
A web beacon (also called a pixel tag or clear GIF) is computer code that communicates information from your device to a server. Some of Forfend’s Services and communications may contain embedded web beacons that allow a server to read certain types of information from your device, such as: the number of people who have viewed certain content, when you opened an email message, and your device IP address. Web beacons help Forfend develop statistical information to provide better and more personalized content.
2.3. Other Local Storage
We and some third parties may use other local storage technologies, for example, local shared objects (also called "Flash cookies") and HTML5 local storage, in certain products and services. Similar to cookies, these technologies store information on your device and can record some information about your activities and preferences. However, these technologies may use different media from cookies. Therefore, you may not be able to control them using standard browser tools and settings. For details about how to disable or delete information contained in Flash cookies, click here.
2.4. Do Not Track
Unless legally required, we do not commit to honoring "Do Not Track" signals in your browser because honoring it would prevent much of the tracking and disclosures described in this Privacy Policy.
3 How we share, transfer, and publicly disclose your personal information
We do not share personal data with other companies, organizations and individuals unless one of the following circumstances applies:
3.1. Sharing with Forfend affiliates:
Your information may be shared within Forfend's affiliates only for explicit, and legitimate purposes, and the sharing is limited only to information required by services. For example, we verify the global uniqueness of accounts before allowing them to be registered.
3.2. Sharing with third party service providers and business partners:
We share your information with third party service providers and business partners providers that we work with to help us operate, provide, analyze, improve, understand, customize, support, and market the Services.
3.3. Third-party services:
When you use third-party services, such as through Third-Party Accounts that integrate with the Products and Services, they may receive your information. When you use third-party services, their own terms and privacy policies will apply to your use of these services.
3.4. Sharing pursuant to laws and regulations:
We may also release your information to third parties when we believe it is appropriate to comply with the law, enforce our terms and policies, or protect ours or others’ rights, property, or safety.
In scenarios 3.1 to 3.3, we will ensure that the lawfulness of this sharing and sign stringent non-disclosure agreements (NDAs) and/or data processing clauses with the companies, organizations, and individuals with whom personal data is shared, requiring them to comply with this Policy and take appropriate confidentiality and security measures when processing personal data.
4 How we store and protect your personal information
We implement a variety of security measures to maintain the safety of your information when you place an order or enter, submit, or access your information. We offer the use of a secure server. All supplied personally identifiable payment information is transmitted via Secure Socket Layer (SSL) technology. After a transaction, your credit card information will not be stored on our servers.
We will retain your personal data for no longer than is necessary for the purposes stated in this Statement, unless otherwise extending the retention period is required by laws or requested by you. The data storage period may vary with scenario, product, and service. The standards we use to determine the retention period are as follows: the time required to retain personal data to fulfill business purposes, including providing products and services; maintaining corresponding transaction and business records as required by laws; ensuring the security of systems, products, and services; handling possible user queries or complaints and locating problems; whether the user agrees to a longer retention period; and whether the laws, contracts, and other equivalencies have special requirements for data retention.
5 How You Can Manage Your Personal Data
5.1 Access, rectification, deletion, data portability, restriction of processing, objection to processing.
Legislation in some countries and regions to which we provide products and services or from where we process personal data, provides that data subjects the rights request (hereinafter referred to as "requests") in regard to the accessing, rectifying, deleting or erasure, porting, restricting, and objecting, the processing of related personal data by our retains. In addition you will have the right to data portability.
5.2 Access Personal Information
5.21 Access your Avatar, Nickname, Email, Password, Fingerprint, News and other basic information:
1. After entering Forfend Security, tap on the sidebar;
2. Tap the column with your Avatar;
3. Access your information.
5.22 Access your Address, Contact Information, PIN Code, Additional Information, Devices, Emergency Contact and other personal information (when you subscribed to additional services):
1. After entering Forfend Security, tap on the sidebar;
2. Tap “My Services”;
3. Tap your service;
4. Access relevant information.
5.23 Access and manage your devices:
1. After entering Forfend Security, tap the sidebar;
2. Tap “My Devices”;
3. Access device information.
5.3 Deleting Personal Information
5.31 Delete your Device Information
1. After entering Forfend Security, tap the

2. icon;
3. Tap “Remove Device”.
5.32 Delete your Event Recording
1. After entering Forfend Security, Tap “Events”;
2. Tap the

3. icon;
4. Delete the selected clip.
5.33 Delete your Account
1. After entering Forfend Security, tap the sidebar;
2. Tap the column with your Avatar;
3. Tap “Email” ;
4. Click “Delete Account”.
5.4 Amending Personal Information
5.41. Change your Avatar, Nickname, Email, Password, Fingerprint, News and other basic information:
1. After entering Forfend Security, tap the sidebar;
2. Tap the column with your Avatar;
3. Make amendments.
5.42 Change your Address, Contact Information, PIN Code, Additional Information, Devices, Emergency Contact and other personal information (when you subscribed to additional services):
1. After entering Forfend Security, tap the sidebar;
2. Tap “My Services” ;
3. Tap your service;
4. Make amendments.
5.5 Withdraw Permission
1. After entering ForfendSecurity, tap the sidebar;
2. Tap the column with your Avatar;
3. Tap “Email” ;
4. Click “Delete Account”.
Note: You have the right to withdraw all consent by deleting your account on the Account Setting page. Once you confirm the delete request, we will remove all your personal information including account information, order related information, service information, and mobile location data.
5.6 California Shine the Light Law
California Civil Code Section 1798.83 permits users who are California residents to obtain from us once a year, free of charge, a list of third parties to whom we have disclosed personal information (if any) for direct marketing purposes in the preceding calendar year. If you are a California resident and you wish to make such a request, please send an e-mail with “California Privacy Rights” in the subject line to forfendofficials@gmail.com 
To exercise your rights as a data subject, you can send an email to forfendofficials@gmail.com. We will ask you to provide sufficient information to enable us to verify your identity and ensure that the applicant is the subject or legally authorized person of the requested information.
You have the right to lodge a complaint with the competent supervisory authority if you believe that the processing of your personal data violates the law and regulation.
6 Children’s and Minors’ Privacy
About the protection of juveniles' personal information. The company attaches great importance to the protection of juveniles' personal information. If you are a juvenile under the age of 16 (according to the legal requirements of EU member states, the minimum age must not be less than 13 years old), you must obtain written consent from your parent or legal guardian before using this product and service. This product and service will protect the personal information of juveniles in accordance with the relevant EU laws and regulations.
The Services are not directed to or intended for use by minors. Consistent with the requirements of applicable law, if we learn that we have received any information directly from a minor, we will take steps to delete or destroy that information. California minors: While the Services are not intended for anyone under the age of 18, if you are a California resident who is under age 18 and you are unable to remove publicly-available content that you have submitted to us, you may request removal by contacting us at: forfendofficials@gmail.com . When requesting removal, you must be specific about the information you want to be removed and provide us with specific information, such as the URL for each page where the information was entered, so that we can find it. We are not required to remove any content or information that: (1) federal or state law requires us or a third party to maintain; (2) was not posted by you; (3) is anonymized so that you cannot be identified; (4) you don’t follow our instructions for removing or requesting removal; or (5) you received compensation or other consideration for providing the content or information. Removal of your content or information from the Service does not ensure complete or comprehensive removal of that content or information from our systems or the systems of our service providers. We are not required to delete the content or information posted by you; our obligations under California law are satisfied so long as we anonymize the content or information or render it invisible to other users and the public.
7 Links to Third-Party Websites, Products, and Services
Occasionally, at our discretion, we may include links to third-party websites on our Services. These third-party websites have separate and independent privacy policies. Please use these third-party websites with caution. We have no responsibility or liability for the content and activities of these linked websites.
8 How your personal information is transferred globally
1.We processes and backs up personal information through its global operations and control facilities. We currently operate data centers in the United States, China and Canada. For the purposes described in this Privacy Policy, your information may be transferred to these data centers in accordance with applicable law.
- Personal information collected and generated in connection with our operations in North America and Australia is processed and stored in data centers in the United States, except for cross-border transfers are permitted under applicable law.
- Personal Information collected and generated in connection with our operations in the People's Republic of China is processed and stored in data centers located in the Mainland of China.
(1) the recipient of the personal data is located within a country that benefits from a full "adequacy" decision of the European Commission;
(2) the recipient may have adhered to binding corporate rules (only for intra group transfers);
(3) the recipient has signed a contract based on "standard contractual clauses" approved by the European Commission, obliging them to protect your personal data; or
(4) where the recipient is located in the US, it is a certified member of the EU-US Privacy Shield and/or “standard contractual clauses”.
In the absence of the above appropriate safeguards, we will ask you for your explicit consent for cross-border transmission of your personal data. In the meantime, security measures such as encryption or de-identification will be adopted for the safety of your personal data.
9 How we update this Privacy Policy
We reserve the right to update this Statement at any time.
Should this policy be revised from time to time, we will release the change notice via various channels, for example, posting the latest version on our official website: https://www.forfendsecurity.com
"Major changes" in this Policy include but are not limited to:
(1) Major changes in our service modes, for example, purposes of personal data processing, types of processed personal data, and ways of using personal data
(2) Major changes in our ownership structure, organizational structure, etc., for example, ownership changes caused by business adjustment, bankruptcy, or acquisition
(3) Changes in the main objects of personal data sharing, transfer, or disclosure
(4) Major changes in your rights regarding personal data processing and the ways in which you can enjoy those rights
(5) Changes in our departments, contacts, and complaint channels responsible for the security of personal data processing
(6) High risks identified in personal data security impact assessment reports
10 Contact us
If you have any comments or questions about this Privacy Policy or any questions relating to our collection, use, or disclosure of your personal information, please contact us at the address below referencing “Privacy Policy”. When we receive privacy or personal information questions, we have a Data Protection Officer (DPO) and a professional team to solve your problems. If your question itself involves a significant issue, we may ask you for more information. If you are not satisfied with the response you received, you can hand over the complaint to the relevant regulatory authority in your jurisdiction. If you consult us, we will provide information on the relevant complaint channels that may be applicable based on your actual situation.
Email:
forfendofficials@gmail.com 
Thank you for taking the time to read our Privacy Policy!
        </pre>
      </p>
      <div class="bottom"></div>
    </div>
</template>

<script>
  export default {
    name: "Alexa",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gilroy-Bold';
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
</style>