<template>
  <div id="content">
      <h3 class="title">1.How to improve fingerprint recognition rate?</h3>
      <p>(1)Register multiple times for one fingerprint. 99 slots are available for programing. <br/>
      (2)Re-register fingerprint every three months. <br/>
      (3)Clean finger and sensor before unlock.
      </p><br/>
<h3 class="title">2.What is the battery life?</h3>
<p>(1)8 duracell procell AA batteries is recommended<br/>
(2)4 batteries will work, but has to be placed next to each other started from one end.<br/>
(3)High Performance Mode: 6 months.<br/>
(4)Low Power Mode: 8-10 months.<br/>
      </p><br/>
<h3 class="title">3.What should I do if I lost key?</h3>
<p>Please contact us with your 4 digits lock number which is on the lock hole.<br/>
      </p><br/>
<h3 class="title">4.Distance between router and gateway?</h3>
<p>20feet/6meters depends on your quality of router <br/>
      </p><br/>
<h3 class="title">5.Distance between gateway and safe?</h3>
<p>120feet/40meters with no barriers.<br/>
      </p><br/>
<h3 class="title">6.How to enter setting mode for gateway?</h3>
<p>Hold top button on the side for three seconds.<br/>
      </p><br/>
<h3 class="title">7.How to turn off the screen of gateway?</h3>
<p>Press top button on the side once.<br/>
      </p><br/>
<h3 class="title">8.Why the signal is weak?</h3>
<p>(1)Antenna has to be installed<br/>
(2)Too many barriers between the safe and gateway.<br/>
(3)The distance between router and gateway is too long<br/>
(4)The distance between safe and gateway is too long<br/>
(5)Too many devices connect to one router.<br/>
(6)Signal of telecom carrier is too weak.<br/>
      </p><br/>
<h3 class="title">9.How many quick setting codes on the safe?</h3>
<p>(1)996 and check button: Switch High Performance and Low Power. Green light means switch to High Performance. Yellow light means switch to Low Power.<br/>
(2)997 and check button: Upload all the data of safe that registered under stand-alone status to App via gateway. Yellow lights turns on until the process is finished. Please don't use this hot key under normal use. Otherwise, all registered fingerprint/password in App will lost the name.<br/>
(3)998 and check button: Turn on/off the sound of keypad. Yellow light means turn off sound. Green light means turn on sound.<br/>
(4)999 and check button: Turn on/off vibration alarm. Green light means turn on vibration alarm. Yellow light means turn off.<br/>
      </p><br/>
<h3 class="title">10.How to delete registered gateway?</h3>
<p>(1)Reset gateway by enter setting menu and selet “Reset”<br/>
(2)Delete gateway in App. Slide to left of specific gateway and delete.<br/>
      </p><br/>
<h3 class="title">11.How to reset gateway? </h3>
<p>(1)Hold gateway top button on the side for three seconds.<br/>
(2)Use up and down button move to Select No.5 “Reset”<br/>
(3)Press top button to enter and select “Yes” <br/>
      </p><br/>
<h3 class="title">12.How to reset safebox?</h3>
<p>(1)Click black button on the inner side of safebox door. <br/>
(2)When yellow light flash, click balck button again.<br/>
(3)When green light flash, it means the reset process is finished.<br/>
      </p><br/>
<h3 class="title">13.How to adjust alarm time?</h3>
<p>(1)Using App go to gateway setting. PERM means premanent.<br/>
(2)Go to gateway setting mode and select NO.4 “Sound”<br/>
      </p><br/>
<h3 class="title">14.If I change living area, how to change time zone.</h3>
<p>(1)Make sure your phone change to new time zone.<br/>
(2)Go to gateway setting mode. Choose No.3 “NET”<br/>
(3)Choose new Wi-Fi for Gateway <br/>
(4)When gateway connect to new gateway, it will automatically update. <br/>
      </p><br/>
<h3 class="title">15.Why it shows failed when I registered password?</h3>
<p>(1)The same password has been registered. The same password cannot be registered for two times<br/>
(2)When you confirm the password for the second time, please make sure it matches the first time. <br/>
      </p><br/>

<h3 class="title">16.How to link sub-account?</h3>
<p>Please check video instruction for link sub-account.<br/>
      </p><br/>
<h3 class="title">17.If I link gateway and safe before I link gateway to server, what should I do to name the existing password and fingerprint?</h3>
<p>You have to use password or fingerprint to unlock safe. Once you match the default name, you could change the name in unlocking method list. Or you could name it in “Fingerprint” or “Password” list. <br/>
      </p><br/>
<h3 class="title">18.How to activate kidnap alarm?</h3>
<p>Enter existing passwor and click House button instead of Check button.<br/>
      </p><br/>
<h3 class="title">19.How many safes can link to one gateway?</h3>
<p>Up to 5 safes. <br/>
      </p><br/>
<h3 class="title">20.Why I cannot choose “Link Server” in gateway setting?</h3>
<p>If you have already link to server, you cannot use this function. If you want to change Wi-Fi, please selet “NET”. Or you could reset the gateway and choose “Link Server”.<br/>
      </p><br/>
<h3 class="title">21.What are the commands for Amazon alexa?</h3>
<p>We name our safe as "bedroom safe". In the following command, you could replace bedroom safe with your own name of safe. If we ask you provide password, you could say any of passwords that registered in safe.<br/>
Make sure you says "Alexa, Open Forfend Security" before every command.<br/>
(1) To unlock safe: <br/>"Alexa, Open Forfend Security"<br/>"Unlock bedroom safe".<br/>
(2) To activate vibration alarm:<br/>"Alexa, Open Forfend Security"<br/> "Activate vibration alarm of bedroom safe" or "Activate bedroom safe".<br/>
(3) To deactivate vibration alarm: <br/>"Alexa, Open Forfend Security"<br/>"Deactivate vibration alarm of bedroom safe" or "Deactivate bedroom safe".<br/>
(4) Turn off gateway alarm: <br/>"Alexa, Open Forfend Security"<br/>"Turn off the alarm".<br/>
(5) Change to high performance mode means the safe will respond quicker: <br/>"Alexa, Open Forfend Security"<br/>"High performance mode for bedroom safe" or "High for bedroom safe".<br/>
(6) Change to low power mode means battery will sustain longer: <br/>"Alexa, Open Forfend Security"<br/>"Low power mode for bedroom safe" or "Low for bedroom safe".<br/>
      </p><br/>
<h3 class="title">22.What are the commands for Google Assistant?</h3>
<p>We name our safe as "bedroom safe". In the following command, you could replace bedroom safe with your own name of safe. If we ask you provide password, you could say any of passwords that registered in safe.<br/>
Make sure you says "Hey,Google" before every command.<br/>
(1) To unlock safe: <br/>"Hey,Google"<br/>"Unlock bedroom safe".<br/>
(2) To activate vibration alarm:<br/>"Hey,Google"<br/> "Arm bedroom safe" or "Set bedroom safe".<br/>
(3) To deactivate vibration alarm: <br/>"Hey,Google"<br/>"Disarm bedroom safe".<br/>
<!-- (4) Turn off gateway alarm: <br/>"Hey,Google"<br/>"Turn off the alarm".<br/> -->
(4) Change to high performance mode means the safe will respond quicker: <br/>"Hey,Google"<br/>"Set bedroom safe High performance mode".<br/>
(5) Change to low power mode means battery will sustain longer: <br/>"Hey,Google"<br/>"Set bedroom safe Low power mode".<br/>
      </p><br/>
<h3 class="title">23.What should I do if I press wrong button of safe during setting？</h3>
<p>During gateway-safe pairing, or passcode registration, If you press wrong button of safe:<br/>
Red Button- Please wait for 10 seconds for a new start<br/>
Green Button- Please press Cancel Button on safe's panel to erase, then you can have a new start <br/>
Black Button- You can press other buttons directly<br/>
      </p><br/>
      <div class="bottom"></div>
 </div>
</template>

<script>
  export default {
    name: "Questions",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  #content {
    overflow-x: hidden;
    position: absolute;
    top: 10px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .title{
      font-family: 'Gilroy-Bold';
      line-height: 30px;
  }
  .subtitle{
      font-family: 'Gilroy-Medium';
  }
  
</style>