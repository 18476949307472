<template>
  <div class="content">
      <h2>How to connect to Google Assistant?</h2>     
      <h3>Preparation</h3>
      <p>
        1. Make sure the smart device has been added to the Forfend Security App and that the device is online.
      </p>
      <p>
        2. Make sure the Google Home App is installed.
      </p>
      <div>
      <img src="~assets/img/google/app.jpg" />
      </div> 
      <h3>Start</h3>
      <p>
        1. Open the Google Home App and tap the plus in the top left corner.
      </p>
      <div>
      <img src="~assets/img/google/home.jpg"  /></div> 
      <p>
        2. Click on Set up device.
      </p>
      <div>
      <img src="~assets/img/google/add.jpg"  />
      </div> 
      <p>
        3. Click Works with Google.
      </p>
      <div>
      <img src="~assets/img/google/setup.jpg"  />
      </div> 
      <p>
        4. Search for Forfend Security and tap Forfend Security.
      </p>
      <div>
      <img src="~assets/img/google/list.jpg"  />
      </div> 
      <div>
      <img src="~assets/img/google/search.jpg"  />
      </div> 
      <p>
        5. This will open the Forfend Security App login page, you can enter the Forfend Security App account password to complete the authorization.
      </p>
      <div>
      <img src="~assets/img/google/login.jpg"  />
      </div> 
      <p>
        6. Wait for it to finish loading and your smart device will be synced to Google Assistant. Now you can use Google Assistant or Google Home App to control your smart devices. Next, you can assign rooms to your devices in the Google Home App.
      </p>
      <div>
      <img src="~assets/img/google/result.jpg"  />
      </div>
 </div>
</template>

<script>
  export default {
    name: "Assistant",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    
    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gilroy-Bold';
  }
  .content h3{
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gilroy-Bold';
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .imgdisplay{
    margin-top: 20px;
    margin-bottom: 30px;
    width: 33%;
    flex: 1;
    float: left;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
</style>