<template>
    <div class="content">
      <h2>End User License Agreement</h2>
      <p>
        You’re about to use Forfend devices and its services via the Forfend Security app. To give you full assurance when using Forfend services, please pay attention to following agreements. By clicking ‘Accept’ you confirm that: You have read and agreed to the Forfend Security <span class="underline">Terms of Service, Privacy Policy</span> and <span class="underline">Data Policy</span>(“the agreement”). The combined use of device and app (the service) is subject to the agreements and indicates your consent of them. This summary is not meant to replace them. It is intended for convenience purpose only. If you do not agree with these agreement, we can’t offer Forfend Security services to you.
      </p>
      
      <div class="bottom"></div>
    </div>
</template>

<script>
  export default {
    name: "Alexa",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gilroy-Bold';
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
  .underline{
    text-decoration: underline;
  }
</style>