<template>
    <div id="content">
        <p>
            Dear User,
        </p>
        <br/>
        <p>
            Like all other smart home devices, our Gateway requires <span class="redTitleBold">2.4 Ghz only</span> wifi network. Not 5 Ghz, not the combined one.
        </p><br/>
        <p><span class='redTitleBold'>
            Firstly, has the wifi already be splitted into 2.4 Ghz and 5 Ghz?
        </span>
        </p><br/>
        <p>
            For some users, the telecom has already split the wifi into 2.4 and 5 Ghz, even with the same name:
        </p><br/>
        <div>
            <img src="~assets/img/wirelessconfiguration/wifi_One.jpg" class="images" />
        </div>
        <br/>
        <div>
            <img src="~assets/img/wirelessconfiguration/wifi_Two.jpg" class="images" />
        </div>
        <br/>
        <p>
            If NOT, the network will be splitted.
        </p>
        <p>
            Here are <span class="redTitleBold">tutorials for some popular brands:</span>
        </p>
        <br/>
        <p>
            TP-Link:
        </p>
        <a class="a" href="https://app.forfendsecurity.com/tplink" target="_blank">https://app.forfendsecurity.com/tplink</a>
        <br/>
        <br/>
        <p>
            D-Link:
        </p>
        <a class="a" href="https://app.forfendsecurity.com/dlink" target="_blank">https://app.forfendsecurity.com/dlink</a>
        <br/>
        <br/>
        <p>
            Asus:
        </p>
        <a class="a" href="https://app.forfendsecurity.com/asus" target="_blank">https://app.forfendsecurity.com/asus</a>
        <br/>
        <br/>
        <p>
            Netgear:
        </p>
        <a class="a" href="https://app.forfendsecurity.com/netgear" target="_blank">https://app.forfendsecurity.com/netgear</a>
        <br/>
        <br/>
        <p class="redtitle">
            If your router is not above, please find the model number and contact our tech support on App.
        </p>
        <br/>
        <p>
            Our tech support team will help with the setup.
        </p>
        <br/>
        <p>
            ----
        </p>
        <p>
            If you don't want to split now, you can <span class="title">register passcode/fingerprint first</span> to use safe's basic features
        </p>
        <br/>
        <p>
            For fingerprint safe, to register passcode:
        </p>
        <a class="a" href="https://youtu.be/xL3T5Mtzz14" target="_blank">https://youtu.be/xL3T5Mtzz14</a>
        <br/>
        <br/>
        <p>
            For fingerprint safe, to register fingerprint:
        </p>
        <a class="a" href="https://youtu.be/1BQ9Pfu8aSc" target="_blank">https://youtu.be/1BQ9Pfu8aSc</a>
        <br/>
        <br/>
        <p>
            For passcode safe, to register passcode:  
        </p>
        <a class="a" href="https://youtu.be/CsvoiRo5wIE" target="_blank">https://youtu.be/CsvoiRo5wIE</a>
        <br/>
        <br/>
        <br/>
        <p>
            Thank you
        </p>
        <p>
            FORFEND Security
        </p>
        <p>
            Customer Service Dept.
        </p>
        <div class="bottom"></div>
   </div>
  </template>
  
  <script>
    export default {
      name: "WirelessConfiguration",
      components:{
},
      data() { 
        return {        
        }
      },
      computed: {
        
      },
      created(){
        
      },
      methods:{    
  
      },
      mounted() {
  
      },
    }
  </script>
  
  <style scoped>
    #content {
      overflow-x: hidden;
      position: absolute;
      top: 10px;
      bottom: 0px;
      left: 10px;
      right: 10px;
      font-family: 'Gilroy-Regular';
      line-height: 25px;
    }
    .title{
          font-family: 'Gilroy-Bold';
          color: #505567;
    }
    .redtitle{
          font-family: 'Gilroy-Bold';
          color: #ff0000;
    }
    .redTitleBold{
        font-family: 'Gilroy-Bold';
          color: #ff0000;
    }

    .subtitle{
          font-family: 'Gilroy-Medium';
    }
    .content h2{
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .bold{
      font-weight: bold;
    }
    .images{
      width: 300px;
      height:500px;   
      display: inline;
      /* position: absolute; */
      top: 0;
      border-radius: 10px;
      
    }
    .playimages{
      width: 50px;
      height: 50px;
      position: absolute;
      left: 75px;
      display: inline;
      margin-top: 30px;
      background-size: 95% 100%;
    }
    .a{
        color: rgb(0, 4, 255);
        text-decoration: underline;
    }
  </style>