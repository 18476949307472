<template>
    <div class="content">
      <h2>How do I connect to Alexa?</h2>
      <p class="p2">
        Before you start, make sure that:
      </p>
      <p>
        (1) Your safe is activated on FORFEND App
      </p>
      <p>
        (2) You have an Alexa-enabled speaker
      </p>
      <p>
        (3) Alexa App is already installed on your smart phone and you have created an account with Alexa
      </p>
      <br>
      <p class="p2">
        1. Tap "Skills & Games" on Alexa App menu.
      </p>
      <div>
      <img src="~assets/img/alexa/skill.jpg" />
      </div>
      <p class="p2">
        2. Search for "ForfendSecuritySkill" and tap "Enable to use".
      </p>
      <div>
      <img src="~assets/img/alexa/enable.jpg" />
      </div>
      <p class="p2">
        3. Enter the FORFEND App account email and password, and tap "Login" to enable the skill.
      </p>
      <div>
      <img src="~assets/img/alexa/login.jpg" />
      </div>
      <!-- <div class="bottom"></div> -->
    </div>
</template>

<script>
  export default {
    name: "Alexa",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gilroy-Bold';
  }

  .p2{
    font-family: 'Gilroy-Bold';
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
</style>