<template>
    <div class="content">
      <h2>
        (D-LINK Router) How to configure separate Wi-Fi SSIDs for 2.4 GHz and 5 GHz bands?
      </h2>
      <p>
        1、On the D-Link home page, click the Settings tab and select Wireless. 
      </p>
      <div>
            <img src="~assets/img/dlink/dlink_pic1.png" class="images" />
      </div>
      <p>
        2、In the 2.4GHz area, set Wi-Fi Name (SSID) to xxx -2.4G; and in the 5GHz area, set Wi-Fi Name (SSID) to xxx -5G, where xxx indicates the Wi-Fi name.
      </p>
      <div>
            <img src="~assets/img/dlink/dlink_pic2.png" class="images" />
      </div>
      <p>
        3、The last step is to Save the settings. When the preceding settings are complete, the new Wi-Fi names will Appear on the Wi-Fi settings page of your mobile phone. 
      </p>
      <br/>
      <p>
        <span class="title">Note:</span> <span class="redtitle">After the Wi-Fi names are changed, you need to reconnect the devices that use this wireless router. The password remains the same if it has not been changed.</span>
      </p>
      <br/>
      <br/>
    </div>
</template>

<script>
  export default {
    name: "DLink",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }

  .title{
          font-family: 'Gilroy-Bold';
          color: #505567;
    }

    .redtitle{
          color: #ff0000;
          font-family: 'Gilroy-Regular';
    }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
</style>