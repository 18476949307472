<template>
    <div class="content">
      <h2>Charmland Technologies Limited – Forfend Security Data Policy</h2>
      <h2>Effective date: Jan,01, 2021</h2>
      <p>
        <pre>
This Data Policy applies to information that Charmland Technologies Limited, a Hong Kong corporation (“ Forfend ,” “ Forfend Security,” “our,” “we” or “us”) collects through www.forfendsecurity.com and all other domains, products, applications (both mobile and desktop), services and content provided by Forfend. (collectively, the “ Services ”).  
YOUR PRIVACY IS IMPORTANT TO US. PLEASE READ THIS DATA POLICY TO LEARN ABOUT THE INFORMATION THAT FORFEND COLLECTS FROM YOU AND HOW FORFEND USES THAT INFORMATION .

BY USING THE SERVICES YOU CONSENT TO THE COLLECTION, USE AND TRANSFER OF YOUR PERSONAL DATA AS DESCRIBED IN THIS DATA POLICY. IF YOU DO NOT AGREE WITH ANY PART OF THIS DATA POLICY, THEN PLEASE DO NOT USE THE SERVICES .

Please be aware that all associated Services and systems MAY BE housed on servers in the United States. If you are located outside of the United States, information we collect (including cookies) are processed and stored in the United States, which may not offer the same level of privacy protection as the country where you reside or are a citizen. By using the Services and providing information to us, you consent to the transfer to and processing of the information in the United States.

Forfend reserves the right to revise and update this Policy from time to time. All changes will take effect immediately when we release them and apply to the Services thereafter. If you are a registered user, we will usually notify you of any changes via email and/or by asking you to review the changes to this policy the next time you login to the Services. Your continued use of the Services after the changes are communicated means your acceptance of those revised changes, so please check the policy regularly for updates.
Email Communications with Us

If you contact us by email, we may record and keep all information we received from you confidential except that:
• we have obtained your permission to share the information; or
• we have given you prior notice identifying that the information will be shared, and whom to share with; or
• the information is aggregate information or other information that does not identify you.

Occasionally, We may need to email you. We may need to send you administrative information, tell you something important about your account or changes to the Services, or update new policies to you.These administrative communications are considered part of the Services, so you may not be able to reject.Please note that we will not email you to ask for your password or other information about your account; if you received such an email, please contact us so we can investigate and take appropriate actions.
Please contact us by:
Email us
forfendofficials@gmail.com

What kind of information do we collect?

When you use the Services, we use the following methods to access your mobile phone information to provide the Services to you and optimize our security to protect your account and personal information.

Information provided by users and other people.
• Your account information . Using your email address to create an Forfend account, or logging in to the Services with other application accounts to provide us with third-party account information. If you refuse to provide such information, you may not be able to use the Services properly.
• Customer Support . You can provide us with information about your use of the Services, including a copy of your information and ways to contact you, so that we can provide you with our customer support. For example, you can use user feedback function or send an e-mail including information related to our device performance or application performance and other issues.
• User usage . We collect information about how users use our products, such as: functions users used; operations users performed; time, frequency, and duration of users' online activities. For example, we collect the time users connected the device in the application and the time of the last connection, as well as the operations of using the account, settings, and other functions in our products. We also collect relevant parameters users setting up our products.

Device Information
• Device properties : such as device model, hardware and software version, signal intensity and other information.
• Device Operations : Information about the operations and behaviors the user performs on the device, such as time, frequency, and duration of users using the function.
• Identity information : unique identifiers, device numbers, and other identifying information (such as information obtained by users logging in to Forfend with other application accounts) and home device numbers (or other unique identification information for the Forfend-owned products associated with the same device or account).
• Device Signals : surrounding Wi-Fi access points, beacons, and cell towers.
• Information from device settings : users allow us to collect information by enabling device settings, such as accessing the user's WIFI or GPS.
• Device Usage : When you use the face detection feature of Forfend Security, your and others persons’ added faces or captured faces will be pushed and displayed in the app. All of the faces will not be transferred to the third party except the feature partner. If you don't want your faces displayed in the app, you can disable the feature in the app and delete all of the faces from the app.
• You can manage all of the recording files in local and cloud. You can delete any of them if you no longer need them.
• Networks and connections : For example, the user's mobile operator or ISP name, language, time zone, etc.
• Cookies : Cookies are small text files that are sent to or accessed from your web browser or your computer’s hard drive. A cookie typically contains the name of the domain (internet location) from which the cookie originated, the “lifetime” of the cookie (i.e., when it expires) and a randomly generated unique number or similar identifier. A cookie also may contain information about your computer, such as user settings, browsing history and activities conducted while using the Services.

The Services use the following cookies:
• Strictly necessary cookies, which are required for the operation of the Services.  Without them, for example, you would not be able to register or log in for the Services that Forfend offers.
• Analytical/performance cookies, which allow Forfend to recognize and count the number of visitors, learn how visitors navigate the Services and improve the Services.
• Functionality cookies, which Forfend uses to recognize you when you return to the Services.
To learn more about cookies and web beacons, visit www.allaboutcookies.org.

Forfend also uses analytics services, such as Google Analytics, to collect Other Information. Generally analytics services do not identify individual users. Many analytics services allow you to opt out of data collection. For example, to learn more about Google Analytics practices and to opt out, visitwww.google.com/settings/ads or by downloading the Google Analytics opt-out browser add-on athttps://tools.google.com/dlpage/gaoptout.

Third-party information

• The Services are provided by external third-party entities, and Forfend cannot obtain the information you generate when using such third-party services. However, if you have expressly consented to the third party obtaining your geographic location, voice commands, etc., the third party will obtain the terminal geographical location information or voice recording interface through Forfend, at that time Forfend and the third party will obtain your geography location information or voice recording information. This information is sensitive and your refusal to provide this information will prevent you from using the above third-party services properly, but it will not affect you using other functions of Forfend.
• Forfend will not actively obtain your personal information from third parties. If future business development requires indirect access to your personal information from a third party, we will obtain your consent before obtaining it. If the personal information processing activities required by Forfend to conduct business in the future exceed the scope of your original consent to provide personal information to third parties, we will obtain your consent before processing your personal information. Ways to obtain your consent include, but are not limited to, updating content of Forfend's Privacy Policy and obtaining your consent for authorization through relevant functions.

How do we use this information?

We utilize all information we owned to support us operate, provide, improve, understand, customize, support and market the Services.
• Our Services : We operate and provide the Services, including providing customer support and improving, repairing, and customizing the Services. We know how people use the Services, and we analyze and use the information we have to evaluate and improve the Services, research, develop and test new services and functions, and perform troubleshooting activities. We will also use your information to reply to you when you contact us. We use cookies to operate, provide, improve, understand and customize the Services.
• Security and privacy : We verify accounts and activities and ensure that the Services are used legally by investigating suspicious activities or violations of our terms, thus promoting the security and privacy of the Services.
• Communications about the Services . We will communicate with you about the Services and functions and let you know about our terms and policies and other important updates.
• Advertising and business information : We may show you advertisements for Forfend products and products with third-party functions based on the products you currently have installed, accessed, used, and purchased. You may receive marketing information that may contain content interesting to you. You can manage these communications because we don't want you to consider these as spam mails. We will respect your choices.

Children’ Privacy
The Services are not directed to or intended for use by minors.  Consistent with the requirements of applicable law, if we learn that we have received any information directly from a minor without his or her parent’s verified consent, we will use that information only to respond directly to that child (or his or her parent or legal guardian) to inform the minor that he or she cannot use the Services and subsequently will delete that information.
If you are a minor under the age of 16 (according to the legal requirements of EU member states, the minimum must not be less than 13 years old), you must obtain written consent from your parent or legal guardian before using this product and its relevant services. This product and the relevant services will protect the personal information of minors in accordance with the relevant EU laws and regulations.
California Minors:   While the Services are not intended for anyone under the age of 18, if you are a California resident who is under age 18 and you are unable to remove publicly-available content that you have submitted to us, you may request removal by contacting us at: forfendofficials@gmail.com .  When requesting removal, you must be specific about the information you want removed and provide us with specific information, such as the URL for each page where the information was entered, so that we can find it. We are not required to remove any content or information that: (1) federal or state law requires us or a third party to maintain; (2) was not posted by you; (3) is anonymized so that you cannot be identified; (4) you don’t follow our instructions for removing or requesting removal; or (5) you received compensation or other consideration for providing the Content or information.  Removal of your content or information from the Service does not ensure complete or comprehensive removal of that content or information from our systems or the systems of our service providers. We are not required to delete the content or information posted by you; our obligations under California law are satisfied so long as we anonymize the content or information or render it invisible to other users and the public.
Legal basis
• Unless a contract is agreed upon, the user agrees, or the law requires, the data subject should have the right not to be affected by automatic decision-making.
• Data managers should take appropriate measures to protect the rights and freedoms of data subjects and legitimate interests, and ensure that data subjects have the right to engage with data managers, express opinions, and challenge decisions.
• We collect the information necessary to provide you with services. During the collection process, we will strictly abide by laws and regulations and will never collect any information prohibited by laws or administrative regulations. We also do not collect the content of your chats or phone calls.

How is this information shared?

• Third-party provider : We cooperate with third-party providers to operate, provide, improve, understand, customize, support and market the Services. Third-party providers are required to use your information complying with our instructions and terms or with your explicit permission in case that we share information with them.
• Third-party services : When you use third-party services integrated with the Services, they may receive information about what you have shared with them. For example, if you use Alexa smart voice services integrated with the Services, they will receive information about what you have shared with them. If you interact with third-party services through the Services, you may provide information directly to such third parties. Please note that when you use third-party services, your use will be governed by their own terms and privacy policies.

How can I manage or delete my information?

• We provide functions allowing users to manage, change, limit or delete their data.
• We will store data until the data is unnecessary for our services and Forfend products or until the user's account is deleted, whichever occurs earlier. That depends on specific situations, such as the nature of the data, the reasons for collecting and processing the data, and the related legal or operational retention needs.
• Change your email address, nickname, and other information : You can change your email address, nickname, and other content that we provide to change functions through the Forfend product related account management functions.
• Delete your Forfend account : You can use our in-app function of Delete My Account to delete your Forfend account at any time (including, if you want, withdraw your consent to use your information). When you delete your Forfend account, we will delete or anonymize the information associated with your account on our server. Please note that if you only remove the Services from your device but without using our in-app function of Delete My Account, your information could be stored longer with us.

How do we respond to legal requests or prevent harm?

If there are good reasons to believe that the law requires us to do so, we may visit, retain, and share your information as required by law (such as a search warrant, court order, or subpoena). Such may include legal requirements from jurisdictions outside of the United States, provided that we are confirmed that the response is required by law in that jurisdiction and has influence on the users in that jurisdiction, and complies with internationally recognized standards. We may also access, maintain and share information for the following purposes: We have good reason to be confirmed that it is necessary to identify, prevent and respond to fraud and other illegal activities; to protect ourselves, yourself and others (including as part of the investigation), or to prevent the risk of death or imminent personal injury.
We will regularly review our products and services for compliance with the Policy. When we receive a formal written complaint, we will contact the complainant to handle the follow-up issues. We will work with appropriate authorities (including the local data protection authority) to resolve any complaints about the transfer of personal data that cannot be resolved directly by us with users.

How will we notify you of changes to this Policy?

We will notify you prior to the revision of this Policy or further processing of personal data for the purpose of collecting personal data so that you can review and comment on the revised Policy before terminated to use the Services.

How do our global services operate?

Forfend may share information within its own company or with third parties to achieve the purposes described in this Policy. For example, for the purposes described in this Policy, information collected in the European Economic Area ("EEA") may be transferred to countries or regions outside the EEA. We promise not to use your information for direct marketing, but in order to improve your using experience, we will keep communications with you. In order to legally transfer data from the EEA to the United States and other countries or regions, we will adhere by the terms of the standard contract approved by the European Commission, take other measures prescribed by EU laws, and will obtain your consent.

California Shine the Light Law

California Civil Code Section 1798.83 permits users who are California residents to obtain from us once a year, free of charge, a list of third parties to whom we have disclosed personal information (if any) for direct marketing purposes in the preceding calendar year.  If you are a California resident and you wish to make such a request, please send an e-mail with “California Privacy Rights” in the subject line to forfendofficials@gmail.com

How can you contact Forfend with questions?

If you have any questions or concerns, please contact us by using the information provided below.
Email us:
forfendofficials@gmail.com
        </pre>
      </p>
      <div class="bottom"></div>
    </div>
</template>

<script>
  export default {
    name: "Alexa",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Gilroy-Bold';
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
</style>