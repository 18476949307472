<template>
    <div class="content">
      <h2>
        (ASUS Router) How to configure separate Wi-Fi SSIDs for 2.4 GHz and 5 GHz bands?
      </h2>
      <p>
        1. In the <span class="title">Advanced Settings</span> area, click <span class="title">Wireless </span>.
      </p>
      <div>
            <img src="~assets/img/asus/asus_pic1.png" class="images" />
      </div>
      <p>
        2. On the <span class="title">General</span> tab page, check whether <span class="title">Enable Smart Connect</span> is <span class="title">On</span> . If Smart Connect is enabled, disable it to separate the SSIDs for the 2.4 GHz and 5 GHz bands.
      </p>
      <div>
            <img src="~assets/img/asus/asus_pic2.png" class="images" />
      </div>
      <p>
        3. In the <span class="title">Band</span> drop-down list, select <span class="title">2.4GHz</span> and then set <span class="title">Network Name (SSID)</span> to <span class="title">  XXX-2.4G</span> , where xxx indicates the Wi-Fi name. Save the setting.
      </p>
      <div>
            <img src="~assets/img/asus/asus_pic3.png" class="images" />
      </div>
      <p>
        4. In the <span class="title">Band</span> drop-down list, select <span class="title">5GHz</span> and then set <span class="title">Network Name (SSID)</span> to  <span class="title">XXX-5G</span> . Save the setting.
      </p>
      <div>
            <img src="~assets/img/asus/asus_pic4.png" class="images" />
      </div>
      <p class="title">
        When the preceding settings are complete, the new Wi-Fi names will Appear on the Wi-Fi settings page of your mobile phone. 
      </p>
      <p>
        <span class="title">Note:</span> <span class="redtitle">After the Wi-Fi names are changed, you need to reconnect the devices that use this wireless router. The password remains the same if it has not been changed.</span>
      </p>
      <br/>
      <br/>
    </div>
</template>

<script>
  export default {
    name: "Asus",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }

  .title{
          font-family: 'Gilroy-Bold';
          color: #505567;
    }

    .redtitle{
          color: #ff0000;
          font-family: 'Gilroy-Regular';
    }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
</style>