<template>
  <div id="content">
      <h2 class="title">For CBE Series Safe setup:</h2><br/>

      <h3 class="title">1. Register a FORFEND account </h3>
      <p>
      (Sometimes verification email will be sent to spam/ads inbox)
      </p><br/>
      <h3 class="title">2. Link Gateway and App:</h3>
      <p>(1) Please <span class='title'>Turn off Cellular Data</span>(LTE, 4G, 5G) of your iphone, and use <span class='title'>Wi-Fi ONLY</span> for setup<br />
        <span class='title'>Do not</span> keep both Wi-Fi and Cellular Data on together<br/>
        <div style="height:8px;"></div>
        (2) Please Select “<span class='title'>2.Link Server</span>” in Gateway<br/>
      You <span class='title'>CANNOT</span> Select NET in this step<br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/2ACk0QyuRRc" target="_blank">
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/1.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/>
      </a><br/>
      </p><br/>
      <h3 class="title">3. Link Gateway and Safe:</h3>
      <p>Please Select "<span class='title'>1.Link Product</span>" in Gateway<br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/NSoWQjqhbHs" target="_blank">
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/4.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/>
      </a><br/>
      </p><br/>

      <h3 class="title">4. Register a Passcode / Fingerprint</h3>
      <p><span class='subtitle'>(1)Password model:</span><br/>
      Press green button once:<br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/SIpv18fDEis" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/17.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>
      <p><span class='subtitle'>(2)Fingerprint model:</span><br/>
      Press green button once for fingerprint, twice for password:<br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/ybu1bGcPaGY" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/21.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/psfGoSTJvV0" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/22.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>

      <h3 class="title">5. Sub-Account Authorization</h3>
      <p>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/qPvl8QxnuhU" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/18.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>

      <h3 class="title">Please keep Gateway plugged in all the time for App remote/notification.</h3><br/>

      <h2 class="title">Additional:</h2><br />
      <h3 class="title">1. Alexa Setup:</h3>
      <p>
      Please make sure:<br/>
      Your safe is connected with FORFEND App<br/>
      You have an Alexa-enabled device<br/>
      Amazon Alexa App is already installed on your smart device<br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/ijA-TThSTHc" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/19.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>

      <h3 class="title">2. Google Assistant Setup:</h3>
      <p>
      Please make sure:<br/>
      Your safe is connected with FORFEND App<br/>
      You have an Google Assistant-enabled device<br/>
      Google Home App is already installed on your smart device<br/>
      After you linked our safe to Google Home, you could use Google Assistant App or Google Speaker to control the safe.<br/>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/p1uyg6JbaLQ" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/googleassistant.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>

      <h3 class="title">3. Turn On / Off Tamper Alarm</h3>
      <p>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/S0eh2M0dO2I" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/2.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>

      <h3 class="title">4. Reset Safe (Erase Everything)</h3>
      <p>
      <div style="height:15px;"></div>
      <a href="https://youtu.be/FZOV4C4jIO8" target="_blank"><img src="https://forfend-public.s3.amazonaws.com/FAQ/5.png" class="images" />
        <img src="https://forfend-public.s3.amazonaws.com/FAQ/play.png" class="playimages"/></a><br/>
      </p><br/>
      <p class="title">If you have any questions, please feel free to contact us:<br/>
      <a href="mailto:ForfendHelp@Gmail.com" style="color: blue">ForfendHelp@Gmail.com</a></p><br/>
      <div class="bottom"></div>
 </div>
</template>

<script>
  export default {
    name: "Questions",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  #content {
    overflow-x: hidden;
    position: absolute;
    top: 10px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .title{
        font-family: 'Gilroy-Bold';
        color: #505567;
  }
  .subtitle{
        font-family: 'Gilroy-Medium';
  }
  .content h2{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .bold{
    font-weight: bold;
  }
  .images{
    width: 200px;
    height: 110px;   
    display: inline;
    /* position: absolute; */
    top: 0;
    border-radius: 10px;
  }
  .playimages{
    width: 50px;
    height: 50px;
    position: absolute;
    left: 75px;
    display: inline;
    margin-top: 30px;
    background-size: 95% 100%;
  }
  
</style>