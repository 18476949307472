<template>
    <div class="content">
      <div>
      <img src="~assets/img/aboutus/aboutUs.jpg" />
      </div>
    </div>
</template>

<script>
  export default {
    name: "Alexa",
    components:{
        
    },
    data() { 
      return {        
      }
    },
    computed: {
      
    },
    created(){
      
    },
    methods:{    

    },
    mounted() {

    },
  }
</script>

<style scoped>
  .content {
    overflow-x: hidden;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 10px;
    right: 10px;
    font-family: 'Gilroy-Regular';
    line-height: 25px;
  }
  .content p{
    line-height: 33px;
  }
  .content h2{
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .content img{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .content h4{
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .blue{
    color: royalblue;
  }
  .bottom{
    height: 110px;
  }
</style>